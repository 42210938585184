export const dev = {
   Auth: {
    userPoolId: "ap-southeast-1_Ot0UXbqFd",
    region: "ap-southeast-1",
    userPoolWebClientId: "94oulpie5jpcteglnmvmd4ksl",
    identityPoolId:"ap-southeast-1:bc981ac6-4059-4182-989b-5f8e0d895c96"
  },
  Storage:{
    AWSS3: {
      bucket:"dev-sgo-meyi",
      region: "ap-southeast-1" 
    },
    customPrefix: {
        public: 'input/'
    }    
  },
  API: {
    endpoints: [{
      name: "Amili-Service",
      endpoint: "https://sl15oe7qc7.execute-api.ap-southeast-1.amazonaws.com/amili"

    },{
      name: "Amili-Survey-Service",
      endpoint: "https://6im1uof907.execute-api.ap-southeast-1.amazonaws.com/dev/"

    }]
  },
  S3:{
    "uploadLab_sequenceing":"input_trigger/sequenceing_file/",
    "uploadLab_qualtrics":"input_trigger/qualtrics-survey-data/",
    "reports_bucket":"dev-sgo-16spipeline",
    "upload_bucket":"dev-sgo-meyi",
    "region": "ap-southeast-1",
    "reports_folder":"output/QC/"
  }
};

export const prod = {
   Auth: {
    userPoolId: "ap-southeast-1_KIMiIqZ1X",
    region: "ap-southeast-1",
    userPoolWebClientId: "6nuih5hob6nm78skqc5deemjh5",
    identityPoolId:"ap-southeast-1:65089546-87e0-4690-8737-a9f917b46a33"
  },
  Storage:{
    AWSS3: {
      bucket:"prod-sgo-meyi",
      region: "ap-southeast-1" 
    },
    customPrefix: {
        public: 'input/'
    }    
  },
  API: {
    endpoints: [{
      name: "Amili-Service",
      endpoint: "https://zhao0tyc31.execute-api.ap-southeast-1.amazonaws.com/amili"

    },{
      name: "Amili-Survey-Service",
      endpoint: "https://qauz9gtc7h.execute-api.ap-southeast-1.amazonaws.com/dev"

    }]
  },
  S3:{
    "uploadLab_sequenceing":"input_trigger/sequenceing_file/",
    "uploadLab_qualtrics":"input_trigger/qualtrics-survey-data/",
    "reports_bucket":"prod-16s-pipeline",
    "upload_bucket":"prod-sgo-meyi",
    "region": "ap-southeast-1",
    "reports_folder":"output/QC/"
  }
};

//export default {dev,prod};